import { useEffect, useLayoutEffect, useState } from "react";
import Head from "next/head";

import { appWithTranslation } from "next-i18next";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "remixicon/fonts/remixicon.css";
import "../styles/globals.css";
import { useRouter } from "next/router";
import Loading from "layout/Loading/Loading";
import { Toaster } from "react-hot-toast";
import LiveChat from "components/LiveChat/LiveChat";
import NewSiteModal from "@/components/NewSiteModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { useTranslation } from "next-i18next";
import { useGlobalStore } from "store/GlobalStore";
import CookiePopup from "@/components/CookiePopup";
import { googleEventAdBlock } from "helpers/googleEvents";
import * as ls from "local-storage";

function MyApp({ Component, pageProps }) {

  const router = useRouter();
  const [pageLoading, setPageLoading] = useState(false);
  const [openNewSiteModal, setOpenNewSiteModal] = useState(false);
  const [adBlockDetected, setAdBlockDetected] = useState(false);
  const { t } = useTranslation("common");
  const [cookieToAccept, setCookieToAccept] = useGlobalStore((state) => [state.cookieToAccept, state.setCookieToAccept]);
  const [showCookiePopup, setShowCookiePopup] = useState(true);
  const [cookieToAcceptLocal, setCookieToAcceptLocal] = useState(true);


  function generateUUID() {
    // UUID v4 standardına uygun bir benzersiz ID oluşturur
    let date = new Date();
    return ` ${moment(date).format("YYYYMMDD")}-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
      const r = Math.random() * 12 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return (v.toString(12))
    });
  }



  const checkAdBlock = () => {
    const script = document.getElementById('gtmmakdos');
    script.onload = () => setAdBlockDetected(false);
    script.onerror = () => setAdBlockDetected(true);

    const ads = document.getElementById('ads-container');
    ads.style.height = '1px';
    if (ads.offsetHeight === 0) {
      setAdBlockDetected(true);
      googleEventAdBlock();
    }

    // const ad = document.createElement('div');
    //   ad.id = 'ads-container';
    //   ad.style.height = '1px';
    //   document.body.appendChild(ad);
    //   if (ad.offsetHeight === 0) {
    //     setAdBlockDetected(true);
    //   }
    //   document.body.removeChild(ad);
  };


  useEffect(() => {
    // if (localStorage.getItem("newSiteModal") !== "true") {
    //   setOpenNewSiteModal(true);
    //   localStorage.setItem("newSiteModal", "true");
    // }

    console.log(".")
    if (!localStorage.getItem("g_id")) {
      const userID = generateUUID();
      localStorage.setItem("g_id", userID);
    }

    checkAdBlock()
  }, [])

  useEffect(() => {
    const handleStart = () => {
      setPageLoading(true);
    };
    const handleComplete = () => {
      setPageLoading(false);
    };

    setShowCookiePopup(true);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);


    // gtag('event', 'screen_view', {
    //   'app_name': 'utkumakdos.com',
    //   'screen_name': `https://makdos.com/${router.locale}${router.asPath}`
    // });

    setCookieToAcceptLocal(cookieToAccept)

  }, [router, cookieToAccept]);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, user-scalable=yes, width=device-width" />
        <link rel="icon" href="/favicon.png" />
        <link rel="icon" type="image/png" href="/g16X16.png" sizes="16x16" />
        <link rel="icon" type="image/png" href="/g32X32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="/g96X96.png" sizes="96x96" />
        <link rel="apple-touch-icon" href="/apple120X120.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple180X180.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple152X152.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="/apple167X167.png" />
        <meta property="og:url" content={`https://makdos.com/${router.locale}${router.asPath}`} />
        <meta property="og:image" content="https://makdos.com/headerLogo.png" />
        <meta property="og:site_name" content="Makdos" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap" rel="stylesheet" />
        {/* <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" /> */}
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#FFDD00" />
        <link rel="canonical" href={`https://makdos.com/${router.locale}${router.asPath}`} />
        <meta name="google-site-verification" content="Bja06HIwMCfLFizjeqe2o_KkbW9WEbqj4lA2jASwUCs" />
        <link rel="preload" href="https://www.googletagmanager.com/gtm.js"></link>
        <link rel="dns-prefetch" href="//googletagmanager.com/" crossOrigin="anonymous" />
        <script dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NPQMCT4B')`}} />

        <script async id="gtmmakdos" src="https://www.googletagmanager.com/gtag/js?id=GTM-NPQMCT4B"></script>
        <script dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'GTM-NPQMCT4B');`}} />
        {/* eski */}

      </Head>
      {pageLoading ?
        <Loading /> :
        <>
          {adBlockDetected &&
            <div className="py-2 bg-discountRed ">
              <p className="container mx-auto text-sm font-medium text-center text-white">{t('adBlockWarning')}</p>
            </div>
          }
          <div id="ads-container" />
          <Component {...pageProps} />
          <Toaster />
          <LiveChat />


          {(!cookieToAcceptLocal && showCookiePopup) &&
            <CookiePopup setShowCookiePopup={setShowCookiePopup} />

          }

          {/* {openNewSiteModal &&
            <NewSiteModal setOpenModal={setOpenNewSiteModal} />
          } */}
        </>
      }
    </>

  );
}

export default appWithTranslation(MyApp);